import { graphql, useStaticQuery } from 'gatsby'

interface Topic {
  description: string
  term_id: number
  image: string
  featured: boolean
}

export function useFeaturedTopics() {
  const data = useStaticQuery(graphql`
    {
      allWpMedia: allWpMediaItem {
        nodes {
          id
          databaseId
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.33)
            }
          }
        }
      }
      wp {
        featuredTopics {
          description
          featured
          image
          term_id
        }
      }
      allWpCategory {
        nodes {
          termTaxonomyId
          name
          slug
        }
      }
    }
  `)
  const allImages = data.allWpMedia.nodes.map((node: any) => {
    return {
      id: node.id,
      databaseId: node.databaseId,
      imageFile: node.localFile?.childImageSharp?.gatsbyImageData,
    }
  })
  const featuredTopics: Topic[] = data.wp.featuredTopics
    .filter((topic: Topic) => topic.featured === true)
    .map((topic: Topic) => ({ ...topic, image: parseInt(topic.image) }))
  const featuredTopicsWithImage: any = []
  allImages.forEach((image: any) => {
    featuredTopics.forEach((topic: Topic) => {
      if (image.databaseId === topic.image) {
        featuredTopicsWithImage.push({ ...image, ...topic })
      }
    })
  })
  // loop over featuredTopicsWithImage and add the category name
  const featuredTopicsWithImageAndCategory: any = []
  featuredTopicsWithImage.forEach((topic: any) => {
    data.allWpCategory.nodes.forEach((category: any) => {
      if (topic.term_id === category.termTaxonomyId) {
        featuredTopicsWithImageAndCategory.push({
          ...topic,
          category: category.name,
          slug: category.slug,
        })
      }
    })
  })
  return { featuredTopics: featuredTopicsWithImageAndCategory }
}
