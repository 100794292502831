import { useStaticQuery, graphql } from 'gatsby'

interface EditorPickPost {
  title: string
  slug: string
  excerpt: string
  featuredImage: any
}

export function useEditorPickPost() {
  const { data } = useStaticQuery(graphql`
    {
      data: allWpPost(filter: { vhl_fields: { editorsPick: { eq: true } } }) {
        nodes {
          title
          excerpt
          slug
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 2)
                }
              }
            }
          }
        }
      }
    }
  `)
  const post: EditorPickPost = {
    ...data.nodes[0],
    featuredImage:
      data.nodes[0].featuredImage.node.localFile?.childImageSharp
        .gatsbyImageData,
  }
  return post
}
