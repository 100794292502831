import { useStaticQuery, graphql } from 'gatsby'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import { getCategory } from '@/utils/category'
import PostCard, { PostCardProps } from '@/components/shared/Post/PostCard'

function FeaturedPostCard(post: PostCardProps) {
  return (
    <PostCard className="flex flex-col space-y-4" {...post}>
      <>
        <PostCard.Image />
        <div className="flex flex-col space-y-2">
          <PostCard.Date />
          <PostCard.Title />
          <PostCard.Excerpt limitText={120} />
        </div>
        <PostCard.Category />
      </>
    </PostCard>
  )
}

function Post(post: PostCardProps) {
  return (
    <PostCard className="flex space-x-4 lg:space-x-5" {...post}>
      <>
        <div className="w-1/3 lg:w-full">
          <PostCard.Image className="min-w-full min-h-full" />
        </div>
        <div className="flex flex-col w-2/3 space-y-4 lg:w-full lg:space-y-3">
          <PostCard.Date />
          <PostCard.Title heading="subheading-5" />
          <PostCard.Excerpt />
          <PostCard.Category />
        </div>
      </>
    </PostCard>
  )
}

const NewSection = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(
        filter: {
          categories: {
            nodes: { elemMatch: { parentDatabaseId: { ne: 515 } } }
          }
        }
        sort: { fields: date, order: DESC }
        limit: 4
      ) {
        nodes {
          title
          slug
          excerpt
          categories {
            nodes {
              name
              parentDatabaseId
              slug
            }
          }
          date(formatString: "DD-MM-YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  desktop: gatsbyImageData(aspectRatio: 1.33)
                  tablet: gatsbyImageData(aspectRatio: 2)
                  mobile: gatsbyImageData(aspectRatio: 1)
                  tabletOtherPost: gatsbyImageData(aspectRatio: 1.33)
                }
              }
            }
          }
        }
      }
    }
  `)
  const posts = data.allWpPost.nodes.map((post: any) => {
    const category = getCategory(post)
    const featuredImage = post.featuredImage.node.localFile?.childImageSharp
    return { ...post, category, featuredImage }
  })
  const featuredPost = posts[0]
  const otherPosts = posts.slice(1)
  const { isTablet } = useBreakpoints()
  const featuredImageType = isTablet ? 'tablet' : 'desktop'
  const otherPostImageType = isTablet ? 'tabletOtherPost' : 'desktop'

  return (
    <>
      {/* Desktop */}
      <div className="flex-col hidden tablet:flex base-container">
        <h1 className="section-heading">Bài viết mới</h1>
        <div className="flex flex-col lg:flex-row lg:space-x-[21px] space-y-8 lg:space-y-0">
          <div className="w-full">
            <FeaturedPostCard
              {...featuredPost}
              image={featuredPost.featuredImage[featuredImageType]}
              link={`/${featuredPost.slug}`}
            />
          </div>
          <div className="flex flex-col w-full space-y-8">
            {otherPosts.map((post: any, index: number) => (
              <Post
                key={index}
                {...post}
                image={post.featuredImage[otherPostImageType]}
                link={`/${post.slug}`}
              />
            ))}
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div className="flex flex-col px-0 base-container tablet:hidden">
        <h1 className="px-4 section-heading">Bài viết mới</h1>
        <div className="flex flex-col space-y-8">
          <div className="w-full">
            <PostCard
              className="flex flex-col"
              {...featuredPost}
              image={featuredPost.featuredImage.mobile}
            >
              <>
                <PostCard.Image />
                <div className="flex flex-col px-4 py-4 space-y-2">
                  <PostCard.Date />
                  <PostCard.Title heading="heading-5" />
                  <PostCard.Excerpt limitText={80} />
                  <PostCard.Category />
                </div>
              </>
            </PostCard>
          </div>
          <div className="flex flex-col w-full px-4 space-y-8">
            {otherPosts.map((post: any, index: number) => (
              <PostCard
                key={index}
                className="flex space-x-3"
                image={post.featuredImage.mobile}
                {...post}
              >
                <>
                  <div className="w-1/2">
                    <PostCard.Image className="min-w-full min-h-full" />
                  </div>
                  <div className="flex flex-col w-full space-y-3">
                    <PostCard.Date />
                    <PostCard.Title heading="heading-6" />
                    <PostCard.Excerpt limitText={70} className="caption-sm" />
                    <PostCard.Category />
                  </div>
                </>
              </PostCard>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default NewSection
