import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'

import Card from './Card'
import { useFeaturedTopics } from '@/hooks/useFeaturedTopics'
import { useBreakpoints } from '@/hooks/useBreakpoints'

const FeaturedTopicSection = () => {
  const { isMobile } = useBreakpoints()
  const { featuredTopics } = useFeaturedTopics()

  const slidesPerView = isMobile ? 1.3 : 3.3
  return (
    <div
      className="flex flex-col pb-8 desktop:pb-24 base-container"
      id="featured-topic-section"
    >
      <h1 className="section-heading">Tâm điểm</h1>
      <div className="flex-row hidden space-x-4 desktop:flex">
        {featuredTopics.map((topic: any, index: number) => (
          <Card
            key={index}
            title={topic.category}
            image={{
              ...topic.imageFile,
              alt: `Featured Topic: ${topic.category}`,
            }}
            href={topic.slug}
          />
        ))}
      </div>
      <div className="block desktop:hidden">
        <Swiper
          spaceBetween={16}
          slidesPerView={slidesPerView}
          pagination={true}
          modules={[Pagination]}
          style={{ paddingBottom: 64 }}
        >
          {featuredTopics.map((topic: any, index: number) => (
            <SwiperSlide
              key={index}
              style={{ height: 'auto', alignSelf: 'stretch' }}
            >
              <Card
                key={index}
                title={topic.category}
                image={{
                  ...topic.imageFile,
                  alt: `Featured Topic: ${topic.category}`,
                }}
                href={topic.slug}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default FeaturedTopicSection
